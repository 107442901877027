import React from 'react';
import styled from 'styled-components';
import { graphql, Link, StaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { Subline } from '../components/Subline';
import { GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';

const Article = styled.article`
  display: flex;
  flex-direction: column;
  margin: 3.5rem 0 3.5rem -120px;
  background-color: #ffffff;
  padding: 0 17px 16px 121px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  border-left: 0 none;

  &:first-child {
    margin-top: 0;
  }
`;

const PostHeader = styled.header`
  padding: 16px;
  display: flex;
  flex-direction: row;
`;

const HeaderImage = styled.div`
  grid-area: profile;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  width: 36px;
  height: 36px;

  & img {
    border-radius: 50%;
  }
`;

const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const HeaderName = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  margin: 0;
  padding: 0;
  color: #262626;
`;

const HeaderLocation = styled.div`
  line-height: 1rem;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PostBody = styled.div`
  padding: 0.6rem 1rem 0 0;
`;


const Title = styled.h2`
  position: relative;
  margin-bottom: 0.75rem;
`;

const Subtitle = styled.h3`
  position: relative;
  margin-bottom: 0.75rem;
`;

const Content = styled.div`
  grid-column: -1 / 1;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & a {
    text-decoration: underline;
  }
`;

const PostDate = styled.div`
  line-height: 1rem;
  font-size: 0.8rem;
  color: #999999;
`;


interface WritingProps {
  title: string;
  subtitle?: string;
  date: string;
  excerpt: string;
  path: string;
  timeToRead: number;
  category: string;
}

interface WritingPropsWithData extends WritingProps {
  data: {
    profileImage: ImageDataLike;
  };
}

class WritingComponent extends React.PureComponent<WritingPropsWithData> {
  public render() {
    const { title, subtitle, date, excerpt, path, timeToRead, category, data } = this.props;
    return (
      <Article>
        <PostHeader>
          <HeaderImage>
            <GatsbyImage image={data.profileImage.childImageSharp.gatsbyImageData} alt="Photo of Charles Gutjahr" />
          </HeaderImage>
          <HeaderDetails>
            <HeaderName>Charles Gutjahr</HeaderName>
            {category === 'opinion' ? (
              <HeaderLocation>A short opinion</HeaderLocation>
            ) : (
              <HeaderLocation>Blog post</HeaderLocation>
            )}
          </HeaderDetails>
        </PostHeader>
        <PostBody>
          <Title>
            <Link to={path}>{title}</Link>
          </Title>
          {subtitle && (
            <Subtitle>
              {subtitle}
            </Subtitle>
          )}
          {category !== 'opinion' && (
            <Subline>
              {date} &mdash; a {timeToRead} minute read on <Link to={`/${kebabCase(category)}`}> {category}</Link>
            </Subline>
          )}
          <Content dangerouslySetInnerHTML={{ __html: excerpt }} />

          <PostDate><time dateTime={date} title={date}>{date}</time></PostDate>

        </PostBody>
      </Article>
    );
  }
}

export const Writing: React.FC<WritingProps> = (props: WritingProps) => (
  <StaticQuery
    query={graphql`
      query {
        profileImage: file(relativePath: { eq: "charles-gutjahr-profile.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 36)
          }
        }
      }
    `}
    // tslint:disable-next-line jsx-no-lambda
    render={data => <WritingComponent data={data} {...props} />}
  />
);
