import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import momentTimezone from 'moment-timezone';
import PhotoPost from '../../models/PhotoPost';
import { media } from '../../utils/media';
// @ts-ignore
import instagramSvg from '../../images/links/instagram.svg';
import { Helmet } from 'react-helmet';

const Post = styled.article<ColumnProps>`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-right: 28px;
  background-color: #ffffff;

  &:first-child {
    margin-top: 0;
  }

  @media ${media.photo1} {
     width: 612px;
  }

  @media ${media.photo2} {
     width: ${props => (Math.min(props.columns, 2) * 612)}px;
  }

  @media ${media.photo3} {
     width: ${props => (Math.min(props.columns, 3) * 612)}px;
  }

  @media ${media.tablet} {
    margin-right: 0px;
  }
`;

const PostHeader = styled.header`
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: row;
`;

const PhotoGrid = styled.div<ColumnProps>`
   @media ${media.photo1} {
     display: grid;
     grid-template-columns: 612px;
     width: 612px;
   }

   @media ${media.photo2} {
     display: grid;
     grid-template-columns: repeat(${(props: any) => (Math.min(props.columns, 2))}, 612px);
     width: ${props => (Math.min(props.columns, 2) * 612)}px;
   }

   @media ${media.photo3} {
     display: grid;
     grid-template-columns: repeat(${(props: any) => (Math.min(props.columns, 3))}, 612px);
     width: ${props => (Math.min(props.columns, 3) * 612)}px;
   }
`;

const HeaderImage = styled.div`
  grid-area: profile;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  width: 36px;
  height: 36px;

  & img {
    border-radius: 50%;
  }
`;

const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
`;

const HeaderName = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  margin: 0;
  padding: 0;
  color: #262626;
`;

const HeaderLocation = styled.div`
  line-height: 1rem;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HeaderLinks = styled.div`
  min-height: 24px;
  padding-top: 7px;
`;

const PostBody = styled.div`
  padding: 16px;
`;

const PostDate = styled.div`
  line-height: 1rem;
  font-size: 0.8rem;
  color: #999999;
`;

const PostTitle = styled.div`
  margin-bottom: 0.8rem;
`;


interface ColumnProps {
  columns: number;
}

interface GenericPhotoPostProps {
  photo: PhotoPost;
  author: any;
  links?: {
    instagram?: {
      url: string;
    };
  };
  columns: number;
  children: any;
}

export class GenericPhotoPost extends React.PureComponent<GenericPhotoPostProps> {

  public render() {
    const { photo, author, children, columns } = this.props;
    const { key, links } = photo;

    const date = momentTimezone(photo.date);
    const shortDate = date.format('D MMMM YYYY, h:mma');
    const zone = momentTimezone(date).tz('Australia/Melbourne').format('z');
    const fullDate = `${shortDate} ${zone} (${zone === 'AEDT' ? 'Australian Eastern Daylight Time' : 'Australian Eastern Standard Time'})`;

    const pageLink = `/photos/${date.format('YYYY/MM/DD')}/${key}`;

    return (
      <Post columns={columns}>
        <PostHeader>
          <HeaderImage>
            <GatsbyImage image={author.profile.childImageSharp.gatsbyImageData} alt="Photo of Charles Gutjahr" />
          </HeaderImage>
          <HeaderDetails>
            <HeaderName>{author.name}</HeaderName>
            <HeaderLocation>{photo.location && photo.location.name}</HeaderLocation>
          </HeaderDetails>
          {links && links.instagram && (
            <HeaderLinks>
              <a href={links.instagram.url} title="View this on Instagram"><img src={instagramSvg} alt="Instagram" /></a>
            </HeaderLinks>
          )}
        </PostHeader>
        <PhotoGrid columns={columns}>
          {children}
        </PhotoGrid>
        <PostBody>
          { photo.caption && <>
            <PostTitle>
              {photo.caption}
              <Helmet>
                <meta property="og:title" content={photo.caption} />
                <meta property="og:locale" content="en_AU" />
              </Helmet>
            </PostTitle>
          </> }
          <PostDate>
            <Link to={pageLink}><time dateTime={date.toISOString()} title={fullDate}>{shortDate}</time></Link>
          </PostDate>
        </PostBody>
      </Post>
    );
  }
}
