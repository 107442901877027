import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import PhotoPost from '../../models/PhotoPost';
// @ts-ignore
import { GenericPhotoPost } from './GenericPhotoPost';
import { media } from '../../utils/media';
import { Helmet } from 'react-helmet';

const GatsbyImageStyled = styled(GatsbyImage)`
  @media ${media.notphone} {
    max-width: 612px;
  }
`;

interface ViewPhotoPostProps {
  photo: PhotoPost;
  author: any;
  links?: {
    instagram?: {
      url: string;
    };
  };
}

export class PhotoSinglePost extends React.PureComponent<ViewPhotoPostProps> {

  public render() {
    const { photo, author } = this.props;
    return (
      <GenericPhotoPost photo={photo} author={author} columns={1}>
        { photo.photo && <>
          <GatsbyImageStyled image={photo.photo.media.childImageSharp.gatsbyImageData} className="singlePhotoImage" alt={photo.caption || ''} />
          <Helmet>
            <meta property="og:image" content={getSrc(photo.photo.media)} />
          </Helmet>
        </>}
      </GenericPhotoPost>
    );
  }
}


