import * as React from 'react';
import styled from 'styled-components';
import { graphql, Link, StaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

const NavigationDiv = styled.div`
  margin: 0 0 0 auto;
  text-align: right;
  width: 5.5rem;
`;

const YearDiv = styled.div`
  padding-top: 2px;
  margin-bottom: 0.3rem;
  letter-spacing: 0.02rem;

  & a {
    color: #555;
  }
`;

const SelectedYearDiv = styled.div`
  border-top: 1px solid #606060;
  border-bottom: 1px solid #606060;

  ${YearDiv} {
    padding-top: 0;
    font-weight: 600;
    letter-spacing: 0;

    & a {
      color: #333;
    }
  }
`;


const MonthDiv = styled.div`
  margin-bottom: 0.3rem;
  font-size: 0.9rem;

  & a {
    color: #666;
  }
`;

const MonthSelectedDiv = styled(MonthDiv)`
  font-weight: 600;
  color: #333;
`;

const StyledLink = styled(Link)`
  & :hover {
    text-decoration: underline;
    color: #303030;
  }
`;

interface NavigationProps {
  selected: string;
  linkSelected?: boolean;
}

interface NavigationPropsWithData extends NavigationProps {
  data: {
    profileImage: ImageDataLike;
  };
}

interface YearGroup {
  year: string;
  months: {
    year: string;
    month: string;
    date: string;
  }[];
}


export class NavigationComponent extends React.Component<NavigationPropsWithData> {
  public static defaultProps = {
    linkToHomepage: true,
  };


  private groupByYear(data: any) {
    return data.months.edges.reduce(
      (accumulator: any[], edge: any) => {
        const context = edge.node.pageContext;
        let year: YearGroup;
        if (accumulator.length > 0) {
          year = accumulator[accumulator.length - 1];
          if (year.year !== context.year) {
            year = { year: context.year, months: [] };
            accumulator.push(year);
          }
        } else {
          year = { year: context.year, months: [] };
          accumulator.push(year);
        }
        year.months.push({ ...context, path: edge.node.path });
        return accumulator;
      },
      []);
  }


  public render(): JSX.Element {
    const { selected, data } = this.props;
    const linkSelected = this.props.linkSelected === true;
    const selectedYear = selected ? selected.substr(0, 4) : '';
    const years = this.groupByYear(data);

    return (
      <NavigationDiv>
        {years.map(year =>
          selectedYear === year.year ? (
            <SelectedYearDiv key={year.year}>
            <YearDiv>
              <StyledLink to={year.months[0].path}>{year.year}</StyledLink>
            </YearDiv>
            {selectedYear === year.year && year.months.map(
            month => month.date === selected ? (linkSelected ? (
              <MonthSelectedDiv key={month.date}>
                <StyledLink to={month.path}>{month.month}</StyledLink>
              </MonthSelectedDiv>
                ) : (
              <MonthSelectedDiv key={month.date}>
                {month.month}
              </MonthSelectedDiv>
            )) : (
              <MonthDiv key={month.date}>
                <StyledLink to={month.path}>{month.month}</StyledLink>
              </MonthDiv>
            ),
            )}
            </SelectedYearDiv>
          ) : (
          <YearDiv key={year.year}>
            <StyledLink to={year.months[0].path}>{year.year}</StyledLink>
          </YearDiv>
          ),
        )}
      </NavigationDiv>
    );
  }
}

//        months: allSitePage(
//          filter: { pageContext: { type: { eq: "nav" }}}
//          sort: { fields: [pageContext___date], order: DESC }
//         ) {

             // context {
             //    month
             //    year
             //    slug
             //    date
             //  }

export default (props: NavigationProps) => (
  <StaticQuery
    query={graphql`
      query {
        months: allSitePage(
          filter: { component: { glob: "**/MonthPage.tsx" }}
          sort: { fields: [ path ], order: DESC }
        ) {
          edges {
            node {
              path,
              pageContext
            }
          }
        }
      }
    `}
    // tslint:disable-next-line jsx-no-lambda
    render={data => <NavigationComponent data={data} {...props} />}
  />
);
